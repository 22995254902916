import React from 'react'

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import StyledModal from '../../components/StyledModal'

const AddModal = (props) => {

    const handleModalClose = () => {
        props.SetModalOpen(false);
    }

    const onChangeGame = (game) => {
        props.SetGameId(game.id);
        props.SetRooms(game.rooms);
    }

    const onChangeRoomId = (value) => {
        props.SetRoomId(value);
    }

    const onChangeGamesPlayed = (event) => {
        props.SetGamesPlayed(event.target.value);
    }

    const onChangeReward = (event) => {
        props.SetReward(event.target.value);
    }

    const handleSave = () => {
        if (props.selectedOption === "new")
            props.handleCreateBonus();

        if (props.selectedOption === "edit")
            props.handleEditBonus();
    }

    return (
        <StyledModal isModalOpen={props.isModalOpen} modalTitle={props.modalTitle} handleModalClose={handleModalClose} handleSave={handleSave}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "25px"
            }}>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="game-label">Game</InputLabel>
                        <Select
                            labelId="game-label"
                            id="game-select"
                            value={props.gameId}
                            label="Game"
                        >
                            {
                                props.games.map((game) => {
                                    return (
                                        <MenuItem key={game.id} value={game.id} onClick={() => onChangeGame(game)}>{game.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="room-label">Room</InputLabel>
                        <Select
                            labelId="room-label"
                            id="room-select"
                            value={props.roomId}
                            label="Room"
                        >
                            {
                                props.rooms.map((room) => {
                                    return (
                                        <MenuItem key={room.id} value={room.id} onClick={() => onChangeRoomId(room.id)}>{room.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <TextField label="Games Played" type="number" value={props.gamesPlayed} onChange={onChangeGamesPlayed} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Reward" type="number" value={props.reward} onChange={onChangeReward} sx={{ width: "80%", mt: "20px" }} />
            </Box>
        </StyledModal >
    )
}

export default AddModal