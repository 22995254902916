import React from 'react'

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import StyledModal from '../../components/StyledModal'

const AddModal = (props) => {

    const handleModalClose = () => {
        props.SetModalOpen(false);
    }

    const onChangeGame = (game) => {
        props.SetGameId(game.id);
        props.SetRooms(game.rooms);
    }

    const onChangeRoomId = (value) => {
        props.SetRoomId(value);
    }

    const onChangeDailyGamesPlayed = (event) => {
        props.SetDailyGamesPlayed(event.target.value);
    }

    const onChangeDailyReward = (event) => {
        props.SetDailyReward(event.target.value);
    }

    const onChangeWeeklyGamesPlayed = (event) => {
        props.SetWeeklyGamesPlayed(event.target.value);
    }

    const onChangeWeeklyReward = (event) => {
        props.SetWeeklyReward(event.target.value);
    }

    const onChangeMonthlyGamesPlayed = (event) => {
        props.SetMonthlyGamesPlayed(event.target.value);
    }

    const onChangeMonthlyReward = (event) => {
        props.SetMonthlyReward(event.target.value);
    }

    const onChangeMonthlyExtraBonus = (event) => {
        props.SetMonthlyExtraBonus(event.target.value);
    }

    const onChangeAnnuallyCashPrize = (event) => {
        props.SetAnnuallyCashPrize(event.target.value);
    }

    const onChangeAnnuallyExtraBonus = (event) => {
        props.SetAnnuallyExtraBonus(event.target.value);
    }

    const handleSave = () => {
        if (props.selectedOption === "new")
            props.handleCreatePrize();

        if (props.selectedOption === "edit")
            props.handleEditPrize();
    }

    return (
        <StyledModal isModalOpen={props.isModalOpen} modalTitle={props.modalTitle} handleModalClose={handleModalClose} handleSave={handleSave}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "25px",
                height: "50vh",
                overflow: "hidden",
                overflowY: "auto"
            }}>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="game-label">Game</InputLabel>
                        <Select
                            labelId="game-label"
                            id="game-select"
                            value={props.gameId}
                            label="Game"
                        >
                            {
                                props.games.map((game) => {
                                    return (
                                        <MenuItem key={game.id} value={game.id} onClick={() => onChangeGame(game)}>{game.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="room-label">Room</InputLabel>
                        <Select
                            labelId="room-label"
                            id="room-select"
                            value={props.roomId}
                            label="Room"
                        >
                            {
                                props.rooms.map((room) => {
                                    return (
                                        <MenuItem key={room.id} value={room.id} onClick={() => onChangeRoomId(room.id)}>{room.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <TextField label="Daily Games Played" type="number" value={props.dailyGamesPlayed} onChange={onChangeDailyGamesPlayed} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Daily Reward" type="number" value={props.dailyReward} onChange={onChangeDailyReward} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Weekly Games Played" type="number" value={props.weeklyGamesPlayed} onChange={onChangeWeeklyGamesPlayed} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Weekly Reward" type="number" value={props.weeklyReward} onChange={onChangeWeeklyReward} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Monthly Games Played" type="number" value={props.monthlyGamesPlayed} onChange={onChangeMonthlyGamesPlayed} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Monthly Reward" type="number" value={props.monthlyReward} onChange={onChangeMonthlyReward} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Monthly Extra Bonus" type="text" value={props.monthlyExtraBonus} onChange={onChangeMonthlyExtraBonus} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Annually Cash Prize" type="text" value={props.annuallyCashPrize} onChange={onChangeAnnuallyCashPrize} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Annually Extra Bonus" type="text" value={props.annuallyExtraBonus} onChange={onChangeAnnuallyExtraBonus} sx={{ width: "80%", mt: "20px" }} />
            </Box>
        </StyledModal >
    )
}

export default AddModal