import React, { useEffect, useState } from 'react'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import { deleteQuery, getQueries, updateQueryStatus } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const Support = (props) => {
    const theme = useTheme();

    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "createTime", label: "Create Time", minWidth: 100, align: "center" },
        { id: "updateTime", label: "Update Time", minWidth: 100, align: "center" },
        { id: "gameUserId", label: "User Id", minWidth: 100, align: "center" },
        { id: "title", label: "Title", minWidth: 100, align: "center" },
        { id: "status", label: "Status", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ]
    const [rows, SetRows] = useState([]);

    const [queriesCount, SetQueriesCount] = useState(0);
    const [queriesIdCap, SetQueriesIdCap] = useState(0);

    const [searchParameters, SetSearchParameters] = useState([]);

    const [selectedSearchParameter, SetSelectedSearchParameter] = useState("");
    const [searchValue, SetSearchValue] = useState("");

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [selectedOption, SetSelectedOption] = useState("");

    const [queryId, SetQueryId] = useState(0);
    const [createTime, SetCreateTime] = useState(new Date().toString());
    const [updateTime, SetUpdateTime] = useState(new Date().toString());
    const [userId, SetUserId] = useState("");
    const [title, SetTitle] = useState("");
    const [description, SetDescription] = useState("");
    const [status, SetStatus] = useState("");

    const handleEdit = (query) => {
        SetQueryId(query.id);
        SetCreateTime(query.createTime);
        SetUpdateTime(query.updateTime);
        SetTitle(query.title);
        SetUserId(query.gameUserId);
        SetStatus(query.status);

        SetModalTitle("Update Status");

        SetSelectedOption("edit");
        SetModalOpen(true);
    }

    const handleDelete = (query) => {
        handleDeleteQuery(query.id);
    }

    const handleSearchParameter = (parameter) => {
        SetSelectedSearchParameter(parameter);
    }

    const handleSearchValue = (event) => {
        SetSearchValue(event.target.value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        SetQueriesIdCap(0);

        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        var response = {};

        response = await getQueries(pageNo + 1, queriesIdCap, rowsPerPage, selectedSearchParameter, searchValue);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((query, index) => {
                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: query,
                            createTime: query.createTime,
                            updateTime: query.updateTime,
                            title: query.title,
                            description: query.description,
                            gameUserId: query.gameUserId,
                            status: query.status,
                            actions: ["edit", "delete"]
                        })
                });

                SetRows(_rows);

                SetQueriesCount(response.data.count);
                SetSearchParameters(response.data.searchParameters);
                SetQueriesIdCap(response.data.queriesIdCap);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleEditQuery = async () => {
        var response = await updateQueryStatus(queryId, status);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleDeleteQuery = async (queryId) => {
        var response = await deleteQuery(queryId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 11, path: "/Support" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage, queriesIdCap]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "25px"
                    }}>
                        <FormControl sx={{ width: "200px", minWidth: "100px" }}>
                            <InputLabel id="parameter-label">Parameters</InputLabel>
                            <Select
                                labelId="parameter-label"
                                id="parameter-select"
                                value={selectedSearchParameter}
                                label="Parameters"
                            >
                                {
                                    searchParameters.map((parameter) => {
                                        return (
                                            <MenuItem key={parameter} value={parameter} onClick={() => handleSearchParameter(parameter)}>{parameter}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <TextField label="Search Value" type="text" value={searchValue} onChange={handleSearchValue} />
                        <Button variant='contained' onClick={() => onClickSearch()} sx={{
                            fontWeight: 600,
                            p: "10px",
                        }}>
                            <SearchIcon sx={{ mr: "5px" }} />Search
                        </Button>
                    </Box>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            <Box sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex"
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}

                    showPagination={true}
                    rowsLength={queriesCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNo}

                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}

                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />
            </Box>

            <AddEditModal
                theme={theme}
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                createTime={createTime}
                SetCreateTime={SetCreateTime}

                updateTime={updateTime}
                SetUpdateTime={SetUpdateTime}

                title={title}
                SetTitle={SetTitle}

                description={description}
                SetDescription={SetDescription}

                userId={userId}
                SetUserId={SetUserId}

                status={status}
                SetStatus={SetStatus}

                selectedOption={selectedOption}
                handleEditQuery={handleEditQuery}
            />
        </Box>
    )
}

export default Support