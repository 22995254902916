import React, { useEffect, useState } from 'react'

import { Box, Button, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
//import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import { generateReferrals, deleteReferral, getReferrals } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const Referrals = (props) => {
    const theme = useTheme();

    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "referralCode", label: "Referral Code", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ]
    const [rows, SetRows] = useState([]);

    const [referralCount, SetReferralCount] = useState(0);
    const [referralIdCap, SetReferralIdCap] = useState(0);

    //const [searchParameters, SetSearchParameters] = useState([]);

    //const [selectedSearchParameter, SetSelectedSearchParameter] = useState("");
    //const [searchValue, SetSearchValue] = useState("");

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [generateReferralCount, SetGenerateReferralCount] = useState(0);

    const handleGenerate = () => {
        SetGenerateReferralCount(0)

        SetModalTitle("Generate Referral Codes");

        SetModalOpen(true);
    }

    const handleDelete = (referral) => {
        handleDeleteReferral(referral.id);
    }

    /* const handleSearchParameter = (parameter) => {
         SetSelectedSearchParameter(parameter);
     }
 
     const handleSearchValue = (event) => {
         SetSearchValue(event.target.value);
     }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }*/

    const onClickReload = () => {
        SetReferralIdCap(0);

        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        var response = {};

        response = await getReferrals(pageNo + 1, referralIdCap, rowsPerPage, "", ""/*selectedSearchParameter, searchValue*/);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((referral, index) => {
                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: referral,
                            referralCode: referral.referralCode,
                            actions: ["delete"]
                        })
                });

                SetRows(_rows);

                SetReferralCount(response.data.count);
                //SetSearchParameters(response.data.searchParameters);
                SetReferralIdCap(response.data.referralIdCap);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleGenerateReferrals = async () => {
        var response = await generateReferrals(generateReferralCount);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.referral);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.referral);
            }
        } else {
            props.showError(response.data.referral);
        }
    };

    const handleDeleteReferral = async (referralId) => {
        var response = await deleteReferral(referralId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.referral);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.referral);
            }
        } else {
            props.showError(response.data.referral);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 9, path: "/Referrals" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage, referralIdCap]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "25px"
                    }}>
                        <Button variant='contained' onClick={() => handleGenerate()} sx={{
                            fontWeight: 600,
                            p: "10px",
                            display: "flex"
                        }}>
                            <AddIcon sx={{ mr: "5px" }} />Generate Codes
                        </Button>
                        {/*
                        <FormControl sx={{ width: "200px", minWidth: "100px" }}>
                            <InputLabel id="parameter-label">Parameters</InputLabel>
                            <Select
                                labelId="parameter-label"
                                id="parameter-select"
                                value={selectedSearchParameter}
                                label="Parameters"
                            >
                                {
                                    searchParameters.map((parameter) => {
                                        return (
                                            <MenuItem key={parameter} value={parameter} onClick={() => handleSearchParameter(parameter)}>{parameter}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <TextField label="Search Value" type="text" value={searchValue} onChange={handleSearchValue} />
                        <Button variant='contained' onClick={() => onClickSearch()} sx={{
                            fontWeight: 600,
                            p: "10px",
                        }}>
                            <SearchIcon sx={{ mr: "5px" }} />Search
                        </Button>
                    */}
                    </Box>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            <Box sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex"
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}

                    showPagination={true}
                    rowsLength={referralCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNo}

                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}

                    handleDelete={handleDelete}
                />
            </Box>

            <AddEditModal
                theme={theme}
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                generateReferralCount={generateReferralCount}
                SetGenerateReferralCount={SetGenerateReferralCount}

                handleGenerateReferrals={handleGenerateReferrals}
            />
        </Box>
    )
}

export default Referrals