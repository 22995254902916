import React, { useEffect, useState } from 'react'

import { Box, Button, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import { getAutomaticPrizeWinner } from '../../services/api';
import DataTable from '../../components/DataTable';

const AutomaticPrizeWinner = (props) => {
    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "createTime", label: "Create Time", minWidth: 100, align: "center" },
        { id: "fromDate", label: "From Date", minWidth: 100, align: "center" },
        { id: "toDate", label: "To Date", minWidth: 100, align: "center" },
        { id: "type", label: "Type", minWidth: 100, align: "center" },
        { id: "userId", label: "User Id", minWidth: 100, align: "center" },
        { id: "gamesPlayed_User", label: "Games Played - User", minWidth: 100, align: "center" },
        { id: "game", label: "Game", minWidth: 100, align: "center" },
        { id: "room", label: "Room", minWidth: 100, align: "center" },
        { id: "gamesPlayed_Required", label: "Games Played - Required", minWidth: 100, align: "center" },
        { id: "reward", label: "Reward", minWidth: 100, align: "center" }
    ]
    const [rows, SetRows] = useState([]);

    const [automaticPrizeWinnerCount, SetAutomaticPrizeWinnerCount] = useState(0);

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [fromDate, SetFromDate] = useState(new Date().toDateString());
    const [toDate, SetToDate] = useState(new Date().toDateString());

    const onChangeFromDate = (value) => {
        SetFromDate(value);
    }

    const onChangeToDate = (value) => {
        SetToDate(value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        SetRows([]);

        var _fromDate = moment(new Date(fromDate), 'DD/MM/YYYY', true).format();
        var _toDate = moment(new Date(toDate), 'DD/MM/YYYY', true).format();

        _fromDate = _fromDate.split('T')[0];
        _toDate = _toDate.split('T')[0];

        var response = await getAutomaticPrizeWinner(pageNo + 1, 0, rowsPerPage, _fromDate, _toDate);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((automaticPrizeWinner, index) => {
                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: automaticPrizeWinner,
                            createTime: automaticPrizeWinner.createTime,
                            fromDate: automaticPrizeWinner.fromDate,
                            toDate: automaticPrizeWinner.toDate,
                            type: automaticPrizeWinner.type,
                            userId: automaticPrizeWinner.userId,
                            gamesPlayed_User: automaticPrizeWinner.gamesPlayed_User,
                            game: automaticPrizeWinner.game,
                            room: automaticPrizeWinner.room,
                            gamesPlayed_Required: automaticPrizeWinner.gamesPlayed_Required,
                            reward: automaticPrizeWinner.reward,
                            actions: []
                        })
                });

                SetRows(_rows);

                SetAutomaticPrizeWinnerCount(response.data.count);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.automaticPrizeWinner);
            }
        } else {
            props.showError(response.data.automaticPrizeWinner);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 19, path: "/Auto Prize Winner" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "25px"
                }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box spacing={3} sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "25px"
                        }}>
                            <DatePicker
                                label="From Date"
                                value={dayjs(fromDate)}
                                onChange={onChangeFromDate}
                                renderInput={(params) => <TextField {...params}
                                    sx={{ width: "80%" }}
                                />}
                            />
                            <DatePicker
                                label="To Date"
                                value={dayjs(toDate)}
                                onChange={onChangeToDate}
                                renderInput={(params) => <TextField {...params}
                                    sx={{ width: "80%" }}
                                />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Button variant='contained' onClick={() => onClickSearch()} sx={{
                        fontWeight: 600,
                        p: "10px",
                    }}>
                        <SearchIcon sx={{ mr: "5px" }} />Search
                    </Button>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>


            <Box sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex"
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}

                    showPagination={true}
                    rowsLength={automaticPrizeWinnerCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNo}

                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}

export default AutomaticPrizeWinner