import React from 'react'

import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

import StyledModal from '../../components/StyledModal'

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';

const AddModal = (props) => {

    const handleModalClose = () => {
        props.SetModalOpen(false);
    }

    const onChangeFromDate = (value) => {
        props.SetFromDate1(value);
    }

    const onChangeToDate = (value) => {
        props.SetToDate1(value);
    }

    const onChangeGame = (game) => {
        props.SetGameId(game.id);
        props.SetGame(game.name);

        props.SetRooms(game.rooms);
        props.SetRoomId(0);
        props.SetRoom([]);

        props.SetRewards([]);
        props.SetRewardId(0);
        props.SetReward("");
    }

    const onChangeRoom = (room) => {
        props.SetRoomId(room.id);
        props.SetRoom(room.name);

        props.SetRewards(room.rewards);
        props.SetRewardId(0);
        props.SetReward("");
    }

    const onChangeReward = (reward) => {
        props.SetRewardId(reward.id);
        props.SetReward(reward.name);
    }

    const onChangeType = (value) => {
        props.SetType(value);
    }

    const onChangeUserId = (event) => {
        props.SetUserId(event.target.value);
    }

    const handleSave = () => {
        props.handleCreatePrizeWinner();
    }

    return (
        <StyledModal isModalOpen={props.isModalOpen} modalTitle={props.modalTitle} handleModalClose={handleModalClose} handleSave={handleSave}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "25px",
                height: "50vh",
                overflow: "hidden",
                overflowY: "auto",
                pt: "10px"
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box spacing={3} sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px"
                    }}>
                        <DatePicker
                            label="From Date"
                            value={dayjs(props.fromDate1)}
                            onChange={onChangeFromDate}
                            renderInput={(params) => <TextField {...params}
                                sx={{ width: "80%" }}
                            />}
                        />
                        <DatePicker
                            label="To Date"
                            value={dayjs(props.toDate1)}
                            onChange={onChangeToDate}
                            renderInput={(params) => <TextField {...params}
                                sx={{ width: "80%" }}
                            />}
                        />
                    </Box>
                </LocalizationProvider>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            value={props.type}
                            label="Type"
                        >
                            <MenuItem key={"Monthly"} value={"Monthly"} onClick={() => onChangeType("Monthly")}>{"Monthly"}</MenuItem>
                            <MenuItem key={"Annually"} value={"Annually"} onClick={() => onChangeType("Annually")}>{"Annually"}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="game-label">Game</InputLabel>
                        <Select
                            labelId="game-label"
                            id="game-select"
                            value={props.gameId}
                            label="Game"
                        >
                            {
                                props.games.map((game) => {
                                    return (
                                        <MenuItem key={game.id} value={game.id} onClick={() => onChangeGame(game)}>{game.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="room-label">Room</InputLabel>
                        <Select
                            labelId="room-label"
                            id="room-select"
                            value={props.roomId}
                            label="Room"
                        >
                            {
                                props.rooms.map((room) => {
                                    return (
                                        <MenuItem key={room.id} value={room.id} onClick={() => onChangeRoom(room)}>{room.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControl fullWidth>
                        <InputLabel id="reward-label">Reward</InputLabel>
                        <Select
                            labelId="reward-label"
                            id="reward-select"
                            value={props.rewardId}
                            label="Reward"
                        >
                            {
                                props.rewards.map((reward) => {
                                    return (
                                        <MenuItem key={reward.id} value={reward.id} onClick={() => onChangeReward(reward)}>{reward.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <TextField label="User Id" type="text" value={props.userId} onChange={onChangeUserId} sx={{ width: "80%", mt: "20px" }} />
            </Box>
        </StyledModal >
    )
}

export default AddModal