import React, { useEffect, useState } from 'react'

import { Box, Button, styled, TextField } from '@mui/material'

import { getSettings, updateSettings } from '../../services/api';

const StyledTextField = styled(TextField)({
    minWidth: "100px",
    width: "50%",
    marginTop: "20px"
});

const Settings = (props) => {

    const [withdraw_commission, SetWithdrawCommission] = useState(0);
    const [welcome_bonus, SetWelcomeBonus] = useState(0);
    const [referee_bonus, SetRefereeBonus] = useState(0);
    const [referrer_bonus, SetReferrerBonus] = useState(0);

    const [url, SetUrl] = useState("");
    const [version, SetVersion] = useState("");

    const [serverIp, SetServerIp] = useState("");
    const [serverPort, SetServerPort] = useState(0);

    const [min_withdrawal, SetMinWithdrawal] = useState(0);

    const [wait_time, SetWaitTime] = useState(0);

    const fetchData = async () => {
        var response = {};

        response = await getSettings();

        if (response.status === 200) {
            if (response.data.status) {
                SetWithdrawCommission(response.data.withdraw_commission);
                SetWelcomeBonus(response.data.welcome_bonus);
                SetRefereeBonus(response.data.referee_bonus);
                SetReferrerBonus(response.data.referrer_bonus);

                SetUrl(response.data.url);
                SetVersion(response.data.version);

                SetServerIp(response.data.serverIp);
                SetServerPort(response.data.serverPort);

                SetMinWithdrawal(response.data.min_withdrawal);
                SetWaitTime(response.data.wait_time);
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleSaveSettings = async () => {
        var response = await updateSettings(withdraw_commission, welcome_bonus, referee_bonus, referrer_bonus, url, version, serverIp, serverPort, min_withdrawal, wait_time);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                fetchData();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 12, path: "/Settings" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                m: "50px",
                mt: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>

            <StyledTextField label="Withdraw Commission (%)" type="number" value={withdraw_commission} onChange={(event) => SetWithdrawCommission(event.target.value)} />
            <StyledTextField label="Welcome Bonus" type="number" value={welcome_bonus} onChange={(event) => SetWelcomeBonus(event.target.value)} />
            <StyledTextField label="Referee Bonus" type="number" value={referee_bonus} onChange={(event) => SetRefereeBonus(event.target.value)} />
            <StyledTextField label="Referrer Bonus" type="number" value={referrer_bonus} onChange={(event) => SetReferrerBonus(event.target.value)} />
            <StyledTextField label="App URL" type="text" value={url} onChange={(event) => SetUrl(event.target.value)} />
            <StyledTextField label="Latest Version" type="text" value={version} onChange={(event) => SetVersion(event.target.value)} />
            <StyledTextField label="Server IP" type="text" value={serverIp} onChange={(event) => SetServerIp(event.target.value)} />
            <StyledTextField label="Server Port" type="number" value={serverPort} onChange={(event) => SetServerPort(event.target.value)} />
            <StyledTextField label="Min Withdrawal" type="number" value={min_withdrawal} onChange={(event) => SetMinWithdrawal(event.target.value)} />
            <StyledTextField label="Wait Time" type="number" value={wait_time} onChange={(event) => SetWaitTime(event.target.value)} />

            <Button variant="contained" onClick={handleSaveSettings} sx={{
                margin: "50px",
                marginBottom: "10px",
                fontWeight: 600,
                width: "100px"
            }}>Save</Button>
        </Box>
    )
}

export default Settings