import React from 'react'

import { Box, TextField, Stack, FormControlLabel, Checkbox } from '@mui/material'
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import StyledModal from '../../components/StyledModal'

const AddModal = (props) => {

    const handleModalClose = () => {
        props.SetModalOpen(false);
    }

    const onChangeStartTime = (value) => {
        props.SetStartTime(value);
    }

    const onChangeEndTime = (value) => {
        props.SetEndTime(value);
    }

    const onChangeTitle = (event) => {
        props.SetTitle(event.target.value);
    }

    const onChangeDescription = (event) => {
        props.SetDescription(event.target.value);
    }

    const onChangeUsers = (value) => {
        props.SetUsers(value);
    }

    const handleSave = () => {
        if (props.selectedOption === "new")
            props.handleCreateMessage();

        if (props.selectedOption === "edit")
            props.handleEditMessage();
    }

    return (
        <StyledModal isModalOpen={props.isModalOpen} modalTitle={props.modalTitle} handleModalClose={handleModalClose} handleSave={handleSave}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "25px"
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}>
                        <DateTimePicker
                            label="Start Time"
                            value={dayjs(props.startTime)}
                            onChange={onChangeStartTime}
                            renderInput={(params) => <TextField {...params}
                                sx={{ width: "80%" }}
                            />}
                        />
                        <DateTimePicker
                            label="End Time"
                            value={dayjs(props.endTime)}
                            onChange={onChangeEndTime}
                            renderInput={(params) => <TextField {...params}
                                sx={{ width: "80%" }}
                            />}
                        />
                    </Stack>
                </LocalizationProvider>
                <TextField label="Title" type="text" value={props.title} onChange={onChangeTitle} sx={{ width: "80%", mt: "20px" }} />
                <TextField label="Description" type="text" value={props.description} onChange={onChangeDescription} sx={{ width: "80%", mt: "20px" }}
                    floatingLabelText="Description"
                    multiline
                    rows={4}
                />
                <TextField label="Users" type="text" value={props.users} onChange={(event) => onChangeUsers(event.target.value)} sx={{ width: "80%", mt: "20px" }} />
                <Box sx={{ width: "80%", mt: "20px" }}>
                    <FormControlLabel
                        label="Broadcasting To All Users"
                        control={
                            <Checkbox
                                checked={props.users === ""}
                            />
                        }
                        sx={{
                            color: props.theme.palette.text.primary,
                        }}
                    />
                </Box>
            </Box>
        </StyledModal >
    )
}

export default AddModal