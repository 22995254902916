import React, { useEffect, useState } from 'react'

import { Box, Button, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import { getManualPrizeWinner, createManualPrizeWinner, deleteManualPrizeWinner } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const ManualPrizeWinner = (props) => {
    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "createTime", label: "Create Time", minWidth: 100, align: "center" },
        { id: "fromDate", label: "From Date", minWidth: 100, align: "center" },
        { id: "toDate", label: "To Date", minWidth: 100, align: "center" },
        { id: "type", label: "Type", minWidth: 100, align: "center" },
        { id: "userId", label: "User Id", minWidth: 100, align: "center" },
        { id: "game", label: "Game", minWidth: 100, align: "center" },
        { id: "room", label: "Room", minWidth: 100, align: "center" },
        { id: "reward", label: "Reward", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" }
    ]
    const [rows, SetRows] = useState([]);

    const [manualPrizeWinnerCount, SetManualPrizeWinnerCount] = useState(0);

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [fromDate, SetFromDate] = useState(new Date().toDateString());
    const [toDate, SetToDate] = useState(new Date().toDateString());

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [fromDate1, SetFromDate1] = useState(new Date().toDateString());
    const [toDate1, SetToDate1] = useState(new Date().toDateString());
    const [type, SetType] = useState("");
    const [userId, SetUserId] = useState("");
    const [game, SetGame] = useState("");
    const [room, SetRoom] = useState("");
    const [reward, SetReward] = useState("");

    const [gameId, SetGameId] = useState(0);
    const [roomId, SetRoomId] = useState(0);
    const [rewardId, SetRewardId] = useState(0);

    const [games, SetGames] = useState([]);
    const [rooms, SetRooms] = useState([]);
    const [rewards, SetRewards] = useState([]);

    const handleNew = () => {
        SetFromDate1(new Date().toDateString());
        SetToDate1(new Date().toDateString());
        SetType("");
        SetUserId("");
        SetGame("");
        SetRoom("");
        SetReward("");

        SetGameId(0);
        SetRoomId(0);
        SetRewardId(0);

        SetRooms([]);
        SetRewards([]);

        SetModalTitle("Create New Winner");

        SetModalOpen(true);
    }

    const handleDelete = (prizeWinner) => {
        handleDeletePrizeWinner(prizeWinner.id);
    }

    const onChangeFromDate = (value) => {
        SetFromDate(value);
    }

    const onChangeToDate = (value) => {
        SetToDate(value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        SetRows([]);

        var _fromDate = moment(new Date(fromDate), 'DD/MM/YYYY', true).format();
        var _toDate = moment(new Date(toDate), 'DD/MM/YYYY', true).format();

        _fromDate = _fromDate.split('T')[0];
        _toDate = _toDate.split('T')[0];

        var response = await getManualPrizeWinner(pageNo + 1, 0, rowsPerPage, _fromDate, _toDate);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((manualPrizeWinner, index) => {
                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: manualPrizeWinner,
                            createTime: manualPrizeWinner.createTime,
                            fromDate: manualPrizeWinner.fromDate,
                            toDate: manualPrizeWinner.toDate,
                            type: manualPrizeWinner.type,
                            userId: manualPrizeWinner.userId,
                            game: manualPrizeWinner.game,
                            room: manualPrizeWinner.room,
                            reward: manualPrizeWinner.reward,
                            actions: ["delete"]
                        })
                });

                SetRows(_rows);

                SetManualPrizeWinnerCount(response.data.count);
                SetGames(response.data.games);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.manualPrizeWinner);
            }
        } else {
            props.showError(response.data.manualPrizeWinner);
        }
    };

    const handleCreatePrizeWinner = async () => {
        var _fromDate = moment(new Date(fromDate1), 'DD/MM/YYYY', true).format();
        var _toDate = moment(new Date(toDate1), 'DD/MM/YYYY', true).format();

        _fromDate = _fromDate.split('T')[0];
        _toDate = _toDate.split('T')[0];

        var response = await createManualPrizeWinner(_fromDate, _toDate, type, userId, game, room, reward);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.prize);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.prize);
            }
        } else {
            props.showError(response.data.prize);
        }
    };

    const handleDeletePrizeWinner = async (prizeId) => {
        var response = await deleteManualPrizeWinner(prizeId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.prize);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.prize);
            }
        } else {
            props.showError(response.data.prize);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 20, path: "/Manual Prize Winner" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "25px"
                }}>
                    <Button variant='contained' onClick={() => handleNew()} sx={{
                        fontWeight: 600,
                        p: "10px",
                        display: (props.userId === undefined) ? "flex" : "none"
                    }}>
                        <AddIcon sx={{ mr: "5px" }} />New Winner
                    </Button>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box spacing={3} sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "25px"
                        }}>
                            <DatePicker
                                label="From Date"
                                value={dayjs(fromDate)}
                                onChange={onChangeFromDate}
                                renderInput={(params) => <TextField {...params}
                                    sx={{ width: "80%" }}
                                />}
                            />
                            <DatePicker
                                label="To Date"
                                value={dayjs(toDate)}
                                onChange={onChangeToDate}
                                renderInput={(params) => <TextField {...params}
                                    sx={{ width: "80%" }}
                                />}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Button variant='contained' onClick={() => onClickSearch()} sx={{
                        fontWeight: 600,
                        p: "10px",
                    }}>
                        <SearchIcon sx={{ mr: "5px" }} />Search
                    </Button>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            {!isModalOpen &&
                <Box sx={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <DataTable
                        rows={rows}
                        columns={columns}

                        showPagination={true}
                        rowsLength={manualPrizeWinnerCount}
                        rowsPerPage={rowsPerPage}
                        page={pageNo}

                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}

                        handleDelete={handleDelete}
                    />
                </Box>
            }

            <AddEditModal
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                gameId={gameId}
                SetGameId={SetGameId}

                roomId={roomId}
                SetRoomId={SetRoomId}

                rewardId={rewardId}
                SetRewardId={SetRewardId}

                fromDate1={fromDate1}
                SetFromDate1={SetFromDate1}

                toDate1={toDate1}
                SetToDate1={SetToDate1}

                type={type}
                SetType={SetType}

                userId={userId}
                SetUserId={SetUserId}

                game={game}
                SetGame={SetGame}

                room={room}
                SetRoom={SetRoom}

                reward={reward}
                SetReward={SetReward}

                games={games}
                SetGames={SetGames}

                rooms={rooms}
                SetRooms={SetRooms}

                rewards={rewards}
                SetRewards={SetRewards}

                handleCreatePrizeWinner={handleCreatePrizeWinner}
            />
        </Box>
    )
}

export default ManualPrizeWinner