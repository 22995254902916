import React from 'react'

import { Box, TextField } from '@mui/material'

import StyledModal from '../../components/StyledModal'

const AddModal = (props) => {

    const handleModalClose = () => {
        props.SetModalOpen(false);
    }

    const onChangeReferralCount = (event) => {
        props.SetGenerateReferralCount(event.target.value);
    }

    const handleSave = () => {
        props.handleGenerateReferrals();
    }

    return (
        <StyledModal isModalOpen={props.isModalOpen} modalTitle={props.modalTitle} handleModalClose={handleModalClose} handleSave={handleSave}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: "25px"
            }}>
                <TextField label="Count" type="number" value={props.generateReferralCount} onChange={onChangeReferralCount} sx={{ width: "80%", mt: "20px" }} />
            </Box>
        </StyledModal >
    )
}

export default AddModal