import React, { useEffect, useState } from 'react'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import { createPrize, deletePrize, getPrizes, updatePrize } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const Prizes = (props) => {
    const theme = useTheme();

    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "game", label: "Game", minWidth: 100, align: "center" },
        { id: "room", label: "Room", minWidth: 100, align: "center" },
        { id: "dailyGamesPlayed", label: "Daily Games Played", minWidth: 100, align: "center" },
        { id: "dailyReward", label: "Daily Reward", minWidth: 100, align: "center" },
        { id: "weeklyGamesPlayed", label: "Weekly Games Played", minWidth: 100, align: "center" },
        { id: "weeklyReward", label: "Weekly Reward", minWidth: 100, align: "center" },
        { id: "monthlyGamesPlayed", label: "Monthly Games Played", minWidth: 100, align: "center" },
        { id: "monthlyReward", label: "Monthly Reward", minWidth: 100, align: "center" },
        { id: "monthlyExtraBonus", label: "Monthly Extra Bonus", minWidth: 100, align: "center" },
        { id: "annuallyCashPrize", label: "Annually Cash Prize", minWidth: 100, align: "center" },
        { id: "annuallyExtraBonus", label: "Annualy Extra Bonus", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ]
    const [rows, SetRows] = useState([]);

    const [prizeCount, SetPrizeCount] = useState(0);

    const [searchParameters, SetSearchParameters] = useState([]);

    const [selectedSearchParameter, SetSelectedSearchParameter] = useState("");
    const [searchValue, SetSearchValue] = useState("");

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [selectedOption, SetSelectedOption] = useState("");

    const [prizeId, SetPrizeId] = useState(0);
    const [gameId, SetGameId] = useState(0);
    const [roomId, SetRoomId] = useState(0);
    const [dailyGamesPlayed, SetDailyGamesPlayed] = useState(0);
    const [dailyReward, SetDailyReward] = useState(0);
    const [weeklyGamesPlayed, SetWeeklyGamesPlayed] = useState(0);
    const [weeklyReward, SetWeeklyReward] = useState(0);
    const [monthlyGamesPlayed, SetMonthlyGamesPlayed] = useState(0);
    const [monthlyReward, SetMonthlyReward] = useState(0);
    const [monthlyExtraBonus, SetMonthlyExtraBonus] = useState("");
    const [annuallyCashPrize, SetAnnuallyCashPrize] = useState("");
    const [annuallyExtraBonus, SetAnnuallyExtraBonus] = useState("");

    const [games, SetGames] = useState([]);
    const [rooms, SetRooms] = useState([]);

    const handleNew = () => {
        SetGameId(0);
        SetRoomId(0);
        SetDailyGamesPlayed(0);
        SetDailyReward(0);
        SetWeeklyGamesPlayed(0);
        SetWeeklyReward(0);
        SetMonthlyGamesPlayed(0);
        SetMonthlyReward(0);
        SetMonthlyExtraBonus("");
        SetAnnuallyCashPrize("");
        SetAnnuallyExtraBonus("");
        SetRooms([]);

        SetModalTitle("Create New Prize");

        SetSelectedOption("new");
        SetModalOpen(true);
    }

    const handleEdit = (prize) => {
        SetPrizeId(prize.id);
        SetGameId(prize.gameId);
        SetRoomId(prize.roomId);
        SetDailyGamesPlayed(prize.dailyGamesPlayed);
        SetDailyReward(prize.dailyReward);
        SetWeeklyGamesPlayed(prize.weeklyGamesPlayed);
        SetWeeklyReward(prize.weeklyReward);
        SetMonthlyGamesPlayed(prize.monthlyGamesPlayed);
        SetMonthlyReward(prize.monthlyReward);
        SetMonthlyExtraBonus(prize.monthlyExtraBonus);
        SetAnnuallyCashPrize(prize.annuallyCashPrize);
        SetAnnuallyExtraBonus(prize.annuallyExtraBonus);

        games.forEach((game) => {
            if (game.id === prize.gameId) {
                SetRooms(game.rooms);
            }
        });

        SetModalTitle("Edit Prize");

        SetSelectedOption("edit");
        SetModalOpen(true);
    }

    const handleDelete = (prize) => {
        handleDeletePrize(prize.id);
    }

    const handleSearchParameter = (parameter) => {
        SetSelectedSearchParameter(parameter);
    }

    const handleSearchValue = (event) => {
        SetSearchValue(event.target.value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        var response = {};

        response = await getPrizes(pageNo + 1, 0, rowsPerPage, selectedSearchParameter, searchValue);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((prize, index) => {
                    let gameName = "";
                    let roomName = "";

                    response.data.games.forEach((game) => {
                        if (game.id === prize.gameId) {
                            gameName = game.name;

                            game.rooms.forEach((room) => {
                                if (room.id === prize.roomId) {
                                    roomName = room.name;
                                }
                            })
                        }
                    });

                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: prize,
                            game: gameName,
                            room: roomName,
                            dailyGamesPlayed: prize.dailyGamesPlayed,
                            dailyReward: prize.dailyReward,
                            weeklyGamesPlayed: prize.weeklyGamesPlayed,
                            weeklyReward: prize.weeklyReward,
                            monthlyGamesPlayed: prize.monthlyGamesPlayed,
                            monthlyReward: prize.monthlyReward,
                            monthlyExtraBonus: prize.monthlyExtraBonus,
                            annuallyCashPrize: prize.annuallyCashPrize,
                            annuallyExtraBonus: prize.annuallyExtraBonus,
                            actions: ["edit", "delete"]
                        })
                });

                SetRows(_rows);

                SetPrizeCount(response.data.count);
                SetSearchParameters(response.data.searchParameters);

                SetGames(response.data.games);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleCreatePrize = async () => {
        var response = await createPrize(gameId, roomId, dailyGamesPlayed, dailyReward, weeklyGamesPlayed, weeklyReward, monthlyGamesPlayed, monthlyReward, monthlyExtraBonus, annuallyCashPrize, annuallyExtraBonus);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleEditPrize = async () => {
        var response = await updatePrize(prizeId, gameId, roomId, dailyGamesPlayed, dailyReward, weeklyGamesPlayed, weeklyReward, monthlyGamesPlayed, monthlyReward, monthlyExtraBonus, annuallyCashPrize, annuallyExtraBonus);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleDeletePrize = async (prizeId) => {
        var response = await deletePrize(prizeId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 17, path: "/Prizes" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "25px"
                    }}>
                        <Button variant='contained' onClick={() => handleNew()} sx={{
                            fontWeight: 600,
                            p: "10px",
                            display: "flex"
                        }}>
                            <AddIcon sx={{ mr: "5px" }} />New Prize
                        </Button>
                        {false &&
                            <FormControl sx={{ width: "200px", minWidth: "100px" }}>
                                <InputLabel id="parameter-label">Parameters</InputLabel>
                                <Select
                                    labelId="parameter-label"
                                    id="parameter-select"
                                    value={selectedSearchParameter}
                                    label="Parameters"
                                >
                                    {
                                        searchParameters.map((parameter) => {
                                            return (
                                                <MenuItem key={parameter} value={parameter} onClick={() => handleSearchParameter(parameter)}>{parameter}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl> &&
                            <TextField label="Search Value" type="text" value={searchValue} onChange={handleSearchValue} /> &&
                            <Button variant='contained' onClick={() => onClickSearch()} sx={{
                                fontWeight: 600,
                                p: "10px",
                            }}>
                                <SearchIcon sx={{ mr: "5px" }} />Search
                            </Button>
                        }
                    </Box>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            {!isModalOpen &&
                <Box sx={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <DataTable
                        rows={rows}
                        columns={columns}

                        showPagination={true}
                        rowsLength={prizeCount}
                        rowsPerPage={rowsPerPage}
                        page={pageNo}

                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}

                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                </Box>
            }

            <AddEditModal
                theme={theme}
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                gameId={gameId}
                SetGameId={SetGameId}

                roomId={roomId}
                SetRoomId={SetRoomId}

                dailyGamesPlayed={dailyGamesPlayed}
                SetDailyGamesPlayed={SetDailyGamesPlayed}

                dailyReward={dailyReward}
                SetDailyReward={SetDailyReward}

                weeklyGamesPlayed={weeklyGamesPlayed}
                SetWeeklyGamesPlayed={SetWeeklyGamesPlayed}

                weeklyReward={weeklyReward}
                SetWeeklyReward={SetWeeklyReward}

                monthlyGamesPlayed={monthlyGamesPlayed}
                SetMonthlyGamesPlayed={SetMonthlyGamesPlayed}

                monthlyReward={monthlyReward}
                SetMonthlyReward={SetMonthlyReward}

                monthlyExtraBonus={monthlyExtraBonus}
                SetMonthlyExtraBonus={SetMonthlyExtraBonus}

                annuallyCashPrize={annuallyCashPrize}
                SetAnnuallyCashPrize={SetAnnuallyCashPrize}

                annuallyExtraBonus={annuallyExtraBonus}
                SetAnnuallyExtraBonus={SetAnnuallyExtraBonus}

                games={games}
                SetGames={SetGames}

                rooms={rooms}
                SetRooms={SetRooms}

                selectedOption={selectedOption}
                handleCreatePrize={handleCreatePrize}
                handleEditPrize={handleEditPrize}
            />
        </Box>
    )
}

export default Prizes