import React, { useEffect, useState } from 'react'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';

import { createMessage, deleteMessage, getMessages, updateMessage } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const Messages = (props) => {
    const theme = useTheme();

    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "startTime", label: "Start Time", minWidth: 100, align: "center" },
        { id: "endTime", label: "End Time", minWidth: 100, align: "center" },
        { id: "title", label: "Title", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ]
    const [rows, SetRows] = useState([]);

    const [msgCount, SetMsgCount] = useState(0);
    const [msgIdCap, SetMsgIdCap] = useState(0);

    const [searchParameters, SetSearchParameters] = useState([]);

    const [selectedSearchParameter, SetSelectedSearchParameter] = useState("");
    const [searchValue, SetSearchValue] = useState("");

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [selectedOption, SetSelectedOption] = useState("");

    const [msgId, SetMsgId] = useState(0);
    const [startTime, SetStartTime] = useState(new Date().toString());
    const [endTime, SetEndTime] = useState(new Date().toString());
    const [title, SetTitle] = useState("");
    const [description, SetDescription] = useState("");
    const [users, SetUsers] = useState("");

    const getUsersList = (users) => {
        let _users = "";

        users.forEach((user, index) => {
            if (index > 0)
                _users += ", ";

            _users += user;
        });

        return _users;
    }

    const handleNew = () => {
        SetStartTime(new Date().toString());
        SetEndTime(new Date().toString());
        SetTitle("");
        SetDescription("");
        SetUsers("");

        SetModalTitle("Create New Message");

        SetSelectedOption("new");
        SetModalOpen(true);
    }

    const handleEdit = (message) => {
        SetMsgId(message.id);
        SetStartTime(message.startTime);
        SetEndTime(message.endTime);
        SetTitle(message.title);
        SetDescription(message.description);
        SetUsers(getUsersList(message.users));

        SetModalTitle("Edit Message");

        SetSelectedOption("edit");
        SetModalOpen(true);
    }

    const handleDelete = (message) => {
        handleDeleteMessage(message.id);
    }

    const handleSearchParameter = (parameter) => {
        SetSelectedSearchParameter(parameter);
    }

    const handleSearchValue = (event) => {
        SetSearchValue(event.target.value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        SetMsgIdCap(0);

        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        var response = {};

        response = await getMessages(pageNo + 1, msgIdCap, rowsPerPage, selectedSearchParameter, searchValue);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((msg, index) => {
                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: msg,
                            startTime: msg.startTime,
                            endTime: msg.endTime,
                            title: msg.title,
                            description: msg.description,
                            actions: ["edit", "delete"]
                        })
                });

                SetRows(_rows);

                SetMsgCount(response.data.count);
                SetSearchParameters(response.data.searchParameters);
                SetMsgIdCap(response.data.msgIdCap);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleCreateMessage = async () => {

        var _users = users.split(',');
        var _users1 = []
        _users.forEach((user) => {
            user.trim();

            if (user !== "")
                _users1.push(user);
        })

        var _startTime = moment(new Date(startTime), 'DD/MM/YYYY', true).format().replace('T', ' ').split('+')[0];
        var _endTime = moment(new Date(endTime), 'DD/MM/YYYY', true).format().replace('T', ' ').split('+')[0];

        _startTime = _startTime.substring(0, _startTime.length - 2) + "00";
        _endTime = _endTime.substring(0, _endTime.length - 2) + "00";

        var response = await createMessage(_startTime, _endTime, title, description, _users1);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleEditMessage = async () => {

        var _users = users.split(',');
        var _users1 = []
        _users.forEach((user) => {
            user.trim();

            if (user !== "")
                _users1.push(user);
        })

        var _startTime = moment(new Date(startTime), 'DD/MM/YYYY', true).format().replace('T', ' ').split('+')[0];
        var _endTime = moment(new Date(endTime), 'DD/MM/YYYY', true).format().replace('T', ' ').split('+')[0];

        _startTime = _startTime.substring(0, _startTime.length - 2) + "00";
        _endTime = _endTime.substring(0, _endTime.length - 2) + "00";

        var response = await updateMessage(msgId, _startTime, _endTime, title, description, _users1);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    const handleDeleteMessage = async (messageId) => {
        var response = await deleteMessage(messageId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.message);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.message);
            }
        } else {
            props.showError(response.data.message);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 10, path: "/Messages" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage, msgIdCap]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "25px"
                    }}>
                        <Button variant='contained' onClick={() => handleNew()} sx={{
                            fontWeight: 600,
                            p: "10px",
                            display: "flex"
                        }}>
                            <AddIcon sx={{ mr: "5px" }} />New Message
                        </Button>
                        <FormControl sx={{ width: "200px", minWidth: "100px" }}>
                            <InputLabel id="parameter-label">Parameters</InputLabel>
                            <Select
                                labelId="parameter-label"
                                id="parameter-select"
                                value={selectedSearchParameter}
                                label="Parameters"
                            >
                                {
                                    searchParameters.map((parameter) => {
                                        return (
                                            <MenuItem key={parameter} value={parameter} onClick={() => handleSearchParameter(parameter)}>{parameter}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <TextField label="Search Value" type="text" value={searchValue} onChange={handleSearchValue} />
                        <Button variant='contained' onClick={() => onClickSearch()} sx={{
                            fontWeight: 600,
                            p: "10px",
                        }}>
                            <SearchIcon sx={{ mr: "5px" }} />Search
                        </Button>
                    </Box>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            <Box sx={{
                width: "100%",
                justifyContent: "center",
                display: "flex"
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}

                    showPagination={true}
                    rowsLength={msgCount}
                    rowsPerPage={rowsPerPage}
                    page={pageNo}

                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}

                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />
            </Box>

            <AddEditModal
                theme={theme}
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                startTime={startTime}
                SetStartTime={SetStartTime}

                endTime={endTime}
                SetEndTime={SetEndTime}

                title={title}
                SetTitle={SetTitle}

                description={description}
                SetDescription={SetDescription}

                users={users}
                SetUsers={SetUsers}

                selectedOption={selectedOption}
                handleCreateMessage={handleCreateMessage}
                handleEditMessage={handleEditMessage}
            />
        </Box>
    )
}

export default Messages