import React, { useEffect, useState } from 'react'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import { createBonus, deleteBonus, getBonus, updateBonus } from '../../services/api';
import DataTable from '../../components/DataTable';
import AddEditModal from './AddEditModal';

const Bonus = (props) => {
    const theme = useTheme();

    const columns = [
        { id: "index", label: "Sr. No", minWidth: 100, align: "center" },
        { id: "game", label: "Game", minWidth: 100, align: "center" },
        { id: "room", label: "Room", minWidth: 100, align: "center" },
        { id: "gamesPlayed", label: "Games Played", minWidth: 100, align: "center" },
        { id: "reward", label: "Reward", minWidth: 100, align: "center" },
        { id: "actions", label: "Actions", minWidth: 100, align: "center" },
    ]
    const [rows, SetRows] = useState([]);

    const [bonusCount, SetBonusCount] = useState(0);

    const [searchParameters, SetSearchParameters] = useState([]);

    const [selectedSearchParameter, SetSelectedSearchParameter] = useState("");
    const [searchValue, SetSearchValue] = useState("");

    const [pageNo, SetPageNo] = useState(0);
    const [rowsPerPage, SetRowsPerPage] = useState(10);

    const [isModalOpen, SetModalOpen] = useState(false);
    const [modalTitle, SetModalTitle] = useState("");

    const [selectedOption, SetSelectedOption] = useState("");

    const [bonusId, SetBonusId] = useState(0);
    const [gameId, SetGameId] = useState(0);
    const [roomId, SetRoomId] = useState(0);
    const [gamesPlayed, SetGamesPlayed] = useState(0);
    const [reward, SetReward] = useState(0);

    const [games, SetGames] = useState([]);
    const [rooms, SetRooms] = useState([]);

    const handleNew = () => {
        SetGameId(0);
        SetRoomId(0);
        SetGamesPlayed(0);
        SetReward(0);
        SetRooms([]);

        SetModalTitle("Create New Bonus");

        SetSelectedOption("new");
        SetModalOpen(true);
    }

    const handleEdit = (bonus) => {
        SetBonusId(bonus.id);
        SetGameId(bonus.gameId);
        SetRoomId(bonus.roomId);
        SetGamesPlayed(bonus.gamesPlayed);
        SetReward(bonus.reward);

        games.forEach((game) => {
            if (game.id === bonus.gameId) {
                SetRooms(game.rooms);
            }
        });

        SetModalTitle("Edit Bonus");

        SetSelectedOption("edit");
        SetModalOpen(true);
    }

    const handleDelete = (bonus) => {
        handleDeleteBonus(bonus.id);
    }

    const handleSearchParameter = (parameter) => {
        SetSelectedSearchParameter(parameter);
    }

    const handleSearchValue = (event) => {
        SetSearchValue(event.target.value);
    }

    const onClickSearch = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const onClickReload = () => {
        if (pageNo === 0)
            fetchData();
        else
            SetPageNo(0);
    }

    const handleChangeRowsPerPage = (event) => {
        SetRowsPerPage(event.target.value);
    }

    const handleChangePage = (event, page) => {
        SetPageNo(page);
    }

    const fetchData = async () => {
        var response = {};

        response = await getBonus(pageNo + 1, 0, rowsPerPage, selectedSearchParameter, searchValue);

        if (response.status === 200) {
            if (response.data.status) {
                const _rows = [];

                response.data.data.forEach((bonus, index) => {
                    let gameName = "";
                    let roomName = "";

                    response.data.games.forEach((game) => {
                        if (game.id === bonus.gameId) {
                            gameName = game.name;

                            game.rooms.forEach((room) => {
                                if (room.id === bonus.roomId) {
                                    roomName = room.name;
                                }
                            })
                        }
                    });

                    _rows.push
                        ({
                            index: (pageNo * rowsPerPage) + (index + 1),
                            data: bonus,
                            game: gameName,
                            room: roomName,
                            gamesPlayed: bonus.gamesPlayed,
                            reward: bonus.reward,
                            actions: ["edit", "delete"]
                        })
                });

                SetRows(_rows);

                SetBonusCount(response.data.count);
                SetSearchParameters(response.data.searchParameters);

                SetGames(response.data.games);

                const table = document.querySelector("table");
                table?.scrollIntoView(true);
            } else {
                props.showError(response.data.bonus);
            }
        } else {
            props.showError(response.data.bonus);
        }
    };

    const handleCreateBonus = async () => {
        var response = await createBonus(gameId, roomId, gamesPlayed, reward);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.bonus);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.bonus);
            }
        } else {
            props.showError(response.data.bonus);
        }
    };

    const handleEditBonus = async () => {
        var response = await updateBonus(bonusId, gameId, roomId, gamesPlayed, reward);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.bonus);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.bonus);
            }
        } else {
            props.showError(response.data.bonus);
        }
    };

    const handleDeleteBonus = async (bonusId) => {
        var response = await deleteBonus(bonusId);

        if (response.status === 200) {
            if (response.data.status) {
                props.showSuccess(response.data.bonus);
                SetModalOpen(false);
                onClickReload();
            } else {
                props.showError(response.data.bonus);
            }
        } else {
            props.showError(response.data.bonus);
        }
    };

    useEffect(() => {
        props.SetPageDetails({ index: 16, path: "/Bonus" })
        fetchData();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [pageNo, rowsPerPage]);

    return (
        <Box
            sx={{
                bgcolor: "background.default",
                mt: (props.userId === undefined) ? "100px" : "0px",
                display: "flex",
                flexDirection: "column"
            }}>

            <Box sx={{
                width: "90%",
                margin: "50px",
                display: "flex",
                justifyContent: "space-between"
            }}>
                <Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "25px"
                    }}>
                        <Button variant='contained' onClick={() => handleNew()} sx={{
                            fontWeight: 600,
                            p: "10px",
                            display: "flex"
                        }}>
                            <AddIcon sx={{ mr: "5px" }} />New Bonus
                        </Button>
                        {false &&
                            <FormControl sx={{ width: "200px", minWidth: "100px" }}>
                                <InputLabel id="parameter-label">Parameters</InputLabel>
                                <Select
                                    labelId="parameter-label"
                                    id="parameter-select"
                                    value={selectedSearchParameter}
                                    label="Parameters"
                                >
                                    {
                                        searchParameters.map((parameter) => {
                                            return (
                                                <MenuItem key={parameter} value={parameter} onClick={() => handleSearchParameter(parameter)}>{parameter}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl> &&
                            <TextField label="Search Value" type="text" value={searchValue} onChange={handleSearchValue} /> &&
                            <Button variant='contained' onClick={() => onClickSearch()} sx={{
                                fontWeight: 600,
                                p: "10px",
                            }}>
                                <SearchIcon sx={{ mr: "5px" }} />Search
                            </Button>
                        }
                    </Box>
                </Box>
                <Button variant='contained' onClick={() => onClickReload()} sx={{
                    fontWeight: 600,
                    p: "10px",
                }}>
                    <RefreshIcon sx={{ mr: "5px" }} />Reload
                </Button>
            </Box>

            {!isModalOpen &&
                <Box sx={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex"
                }}>
                    <DataTable
                        rows={rows}
                        columns={columns}

                        showPagination={true}
                        rowsLength={bonusCount}
                        rowsPerPage={rowsPerPage}
                        page={pageNo}

                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}

                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                </Box>
            }

            <AddEditModal
                theme={theme}
                modalTitle={modalTitle}

                isModalOpen={isModalOpen}
                SetModalOpen={SetModalOpen}

                gameId={gameId}
                SetGameId={SetGameId}

                roomId={roomId}
                SetRoomId={SetRoomId}

                gamesPlayed={gamesPlayed}
                SetGamesPlayed={SetGamesPlayed}

                reward={reward}
                SetReward={SetReward}

                games={games}
                SetGames={SetGames}

                rooms={rooms}
                SetRooms={SetRooms}

                selectedOption={selectedOption}
                handleCreateBonus={handleCreateBonus}
                handleEditBonus={handleEditBonus}
            />
        </Box>
    )
}

export default Bonus